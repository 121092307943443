// ** Third Party Imports
import { useLocation, useNavigate } from 'react-router-dom'

// ** Styled Components
import { BreadcrumbsItem, BreadcrumbsLabel, BreadcrumbsWrapper } from './styles'

// ** Component Imports
import { IconButton } from '../IconButton'

// ** Icon Imports
import { ChevronRight } from '../../icons'

// ** Helpers Functions
import { useBreadcrumbs } from '@/shared/lib/useBreadcrumbs'

export const Breadcrumbs = () => {
	// ** Hooks
	const breadcrumbs = useBreadcrumbs()
	const location = useLocation()
	const navigate = useNavigate()

	// this is used as an edge case for the chat history page;
	// when on it, clicking the User Profile breadcrumb should navigate to the user profile page;
	// more precisely user-profile?tab=chat-history
	const isOnChatHistoryPage = location.pathname.includes('chat-history')

	return (
		<>
			{breadcrumbs && breadcrumbs.length > 0 && (
				<BreadcrumbsWrapper aria-label='breadcrumb'>
					{breadcrumbs.map((item, index) => (
						<>
							<BreadcrumbsItem key={index}>
								{index === breadcrumbs.length - 1 ? ( // last
									<BreadcrumbsLabel $active>{item.label}</BreadcrumbsLabel>
								) : index === 0 ? ( // first
									<BreadcrumbsLabel
										onClick={() => {
											if (isOnChatHistoryPage) {
												navigate('./user-profile?tab=chat-history')
											} else {
												navigate(`../${breadcrumbs[0].path}`)
											}
										}}
									>
										{item.label}
									</BreadcrumbsLabel>
								) : (
									// in between
									<BreadcrumbsLabel
										onClick={() => {
											navigate(-(breadcrumbs.length - (index + 1))) // go back to the clicked breadcrumb
										}}
									>
										{item.label}
									</BreadcrumbsLabel>
								)}
							</BreadcrumbsItem>

							{/* don't render chevron if last el */}
							{index === breadcrumbs.length - 1 ? null : (
								<IconButton
									icon={<ChevronRight />}
									width={1.35}
									height={1.35}
									ariaLabel='chevron-right'
								/>
							)}
						</>
					))}
				</BreadcrumbsWrapper>
			)}
		</>
	)
}
