// ** Third Party Imports
import { createGlobalStyle } from 'styled-components'

// ** Utility Imports
import { pxToEm } from '@/shared/styles'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
  }

  html,
  body {
    scroll-behavior: smooth;

    /* 769px is used to enable overscroll on tablets(768px) */
    @media screen and (min-width: ${pxToEm(769)}) {
      overscroll-behavior-y: none;
    }
  }

  a {
    color: inherit;
  }

  /* Disable native tooltip styling */
  .apexcharts-tooltip.custom-chart-tooltip-class {
    color: unset;
    border: unset !important;
    box-shadow: 0px 2px 8px 0px rgba(9, 106, 99, 0.25) !important;
    background: unset !important;
    border-radius: 0.5rem;
    background-color: unset !important;
  }

  /* Custom year picker dropdown */
  #year-picker-portal-root {
    /* React date picker dropdown */
    & .react-datepicker-popper {
      z-index: 3;
      padding-top: 0.5rem;

      & .react-datepicker {
        /* scrollable container */
        max-height: 230px;
        overflow-y: scroll;

        /* Customize dropdown borders, background, fonts */
        color: #474D65;
        border: none;
        font-size: 0.875rem;
        box-shadow: 0px 2px 0.5rem 0px rgba(9, 106, 99, 0.25);
        font-weight: 400;
        font-family: inherit;
        border-radius: 0.25rem;
        background-color: ${({ theme }) => theme.pallette.geniiWhite};

        /* Hide dropdown top arrow */
        & .react-datepicker__triangle {
          display: none;
        }

        & .react-datepicker__year--container {
          /* Hide default header */
          & .react-datepicker__header {
            display: none;
          }

          /* Dropdown element wrapper (two levels above single year) */
          & .react-datepicker__year {
            margin: 0;
            padding: 0.5rem 0.75rem;

            /* Dropdown element wrapper (one levels above single year) */
            & .react-datepicker__year-wrapper {
              justify-content: space-between;
              width: 14.75rem;

              /* Single year */
              & .react-datepicker__year-text {
                height: 1.75rem;
                width: 3.25rem;
                line-height: 2;

                &:hover {
                  border-radius: 0.25rem;
                  background-color: #f0f0f0;
                }

                /* User selected single year */
                &.react-datepicker__year-text--selected {
                  border-radius: 1.875rem;
                  background-color: ${({ theme }) =>
										theme.pallette.primary.main};
                }

                /* Keyboard selected single year */
                &.react-datepicker__year-text--keyboard-selected {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .specific-start-year-filter .react-datepicker__year-wrapper .react-datepicker__year-text--disabled{
    display: none !important;
  }

  /* -n+3 selects the last three elements (counting backward from the end) */
  .specific-start-year-filter .react-datepicker__year-wrapper .react-datepicker__year-text:nth-last-child(-n+3) {
    display: none;
  }
`
