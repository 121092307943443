// ** React Imports
import { useRef } from 'react'

// ** Third Party Imports
import ContentLoader from 'react-content-loader'
import { useOnClickOutside } from 'usehooks-ts'

// ** Component Imports
import { Avatar, Typography } from '@/shared/ui'

// ** Store & Actions
import { setIsSidebarOpen } from '@/entities/layout'
import { useAppSelector, useAppDispatch } from '@/shared/lib/hooks'

// ** Feature Imports
import { LogoutButton } from '@/features/auth/logout'
import { CloseSidebarIcon } from '@/features/layout/toggle'

// ** Action Imports
import { useGetCurrentUserQuery } from '@/entities/user'

// ** Utility Imports
import { env, getFullName, getInitials } from '@/shared/lib'

// ** Styled Components
import {
	SidebarContent,
	SidebarWrapper,
	SidebarTopText,
	SidebarLinks,
	SidebarLink,
	DashboardIcon,
	ClientManagementIcon,
	TaxStrategiesIcon,
	Separator,
	SidebarProfileLink,
	Header,
	ClientDashboardIcon,
	CreateNewWidget
} from './styles'

export const Sidebar = () => {
	// ** Hooks
	const dispatch = useAppDispatch()
	const sidebarRef = useRef<HTMLDivElement>(null)
	const isSidebarOpen = useAppSelector((state) => state.layout.isSidebarOpen)
	// queries
	const { data: userData, isFetching } = useGetCurrentUserQuery()

	// ** Variables
	// user data
	const photoKey = userData?.photoKey ?? ''
	const photoUrl =
		photoKey !== null && photoKey.length > 0
			? `${env.cloudFrontUrl}/${photoKey}`
			: null
	const fullName = getFullName(userData?.firstName, userData?.lastName)

	useOnClickOutside(sidebarRef, () => dispatch(setIsSidebarOpen(false)))

	const closeSidebar = () => dispatch(setIsSidebarOpen(false))

	return (
		<SidebarWrapper>
			<SidebarContent $isSidebarOpen={isSidebarOpen} ref={sidebarRef}>
				<Header>
					<CloseSidebarIcon />
				</Header>
				{/* Sidebar user info */}
				<SidebarProfileLink to='/user-profile?tab=edit' onClick={closeSidebar}>
					{isFetching ? (
						<ContentLoader
							width={216}
							speed={1.5}
							height={40}
							opacity={0.5}
							viewBox='0 0 216 40'
							interval={0.2}
							backgroundColor='#fafafa'
							foregroundColor='rgba(171,212,210,0.6)'
						>
							<circle cx='20' cy='20' r='20' />
							<rect x='48' y='8' rx='3' ry='3' width='88' height='6' />
							<rect x='48' y='26' rx='3' ry='3' width='52' height='6' />
						</ContentLoader>
					) : (
						<>
							<Avatar
								src={photoUrl ?? undefined}
								width='2.5rem'
								height='2.5rem'
								fallbackText={getInitials(fullName)}
							/>
							<SidebarTopText>
								<Typography variant='body1' textTrunc={1}>
									{fullName}
								</Typography>
								<Typography variant='body3' textTrunc={1}>
									{userData?.email}
								</Typography>
							</SidebarTopText>
						</>
					)}
				</SidebarProfileLink>

				<Separator />

				{/* Sidebar links */}
				<SidebarLinks>
					{/* Single link */}
					<SidebarLink onClick={closeSidebar} to='/'>
						<DashboardIcon />
						<Typography variant='body1'>Dashboard</Typography>
					</SidebarLink>

					{/* Single link */}
					<SidebarLink onClick={closeSidebar} to='/client-management'>
						<ClientManagementIcon />
						<Typography variant='body1'>Client Management</Typography>
					</SidebarLink>

					{/* Single link */}
					<SidebarLink onClick={closeSidebar} to='/client-dashboard'>
						<ClientDashboardIcon />
						<Typography variant='body1'>Client Dashboard</Typography>
					</SidebarLink>

					{/* Single link */}
					<SidebarLink onClick={closeSidebar} to='/tax-strategies'>
						<TaxStrategiesIcon />
						<Typography variant='body1'>Tax Strategies</Typography>
					</SidebarLink>
				</SidebarLinks>

				{/* Create new widget */}
				<CreateNewWidget />

				{/* Sidebar logout button */}
				<LogoutButton />
			</SidebarContent>
		</SidebarWrapper>
	)
}
